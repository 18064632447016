<template>
    <div class="products-page my-5">

        <div class="container">
            <div class="text-center pb-5">
                <img src="@/assets/klos.svg" />
                <h2>CHLEBY</h2>
            </div>
            <div class="row">
                <div v-for="product in breadList" :key="product.id" class="product-on-list col-12 col-md-6 col-xl-3">
                    <productDescription :product="product"/>
                </div>
            </div>
        </div>

        <div class="container mt-5">
            <div class="text-center pb-5">
                <img src="@/assets/klos.svg" />
                <h2>BUŁKI</h2>
            </div>
            <div class="row">
                <div v-for="product in bunsList" :key="product.id" class="product-on-list col-12 col-md-6 col-xl-3">
                    <productDescription :product="product"/>
                </div>
            </div>
        </div>

        <div class="container mt-5">
            <div class="text-center pb-5">
                <img src="@/assets/klos.svg" />
                <h2>SŁODKIE WYPIEKI</h2>
            </div>
            <div class="row">
                <div v-for="product in pastryList" :key="product.id" class="product-on-list col-12 col-md-6 col-xl-3">
                    <productDescription :product="product"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// import ProductInfo from '@/components/partial/ProductInfo.vue';
import ProductDescription from '@/components/partial/ProductDescription.vue';
import { mapGetters } from "vuex";

export default {
    name: "Order",
    components: {
        // ProductInfo,
        ProductDescription
    },
    computed: {
        ...mapGetters({breadList: 'getBreads', bunsList: 'getBuns', pastryList: 'getPastries'}),
    },
    data() {
        return {
            breads: [],
            buns: [],
            pastry: []
        }
    },
    methods: {

        // addProduct(product){

        //     const productTemplate = { 
        //         ...product,
        //         product_id: product.id,
        //         amount: {
        //             sunday: 0,
        //             monday: 0,
        //             tuesday: 0,
        //             wednesday: 0,
        //             thursday: 0,
        //             friday: 0,
        //             saturday: 0,
        //         },
        //         date_amount: 0,
        //         isValid: false
        //     };

        //     this.$store.dispatch('order/loadBasket')
        //         .then( () => {
        //             this.$store.dispatch('order/updateProductTemplate', productTemplate)
        //                 .then( () => {
        //                     const vNodesMsg = this.$createElement( 'a', { attrs: { href: ['/order/add'] } }, 'przejdź do koszyka' );

        //                     this.$bvToast.toast( [vNodesMsg], { 
        //                         title: 'dodano do koszyka ' + product.name,
        //                         autoHideDelay: 1000,
        //                         appendToast: true,
        //                         solid: true,
        //                         variant: 'warning',
        //                         toaster: 'b-toaster-bottom-center'
        //                     })
        //                 });
        //         });

        // },
    }
};
</script>
